<script setup>
import {ref} from "vue";
import {logout} from "@/plugins/users";
import axios from "@/plugins/axios";
import toast from "@/plugins/bulmatoast";


import {useRouter} from "vue-router";

const router = useRouter()

const role = ref(localStorage.getItem("role"))
const user = ref(localStorage.getItem("username"))


const selectProject = () => {
  router.push({name: 'Trunks', params: {project: "all"}})
}
const collectGoips = () => {
  axios.get(`settings/collect-goips`).then(() => {
    toast({
      message: "Список шлюзов успешно собран",
      type: "is-success",
    });
  }).catch((error) => {
    toast({
      message: error.response.data.detail,
      type: "is-danger",
    });
  })

}
const collectData = () => {
  axios.get(`settings/collect-data-trunks`).then(() => {
    toast({
      message: "Актуальные данные по состоянию каналов успешно сохранены",
      type: "is-success",
    });
  }).catch(error => {
    toast({
      message: error.response.data.detail,
      type: "is-danger",
    });
  })
}

const removeExtraTrunks = () => {
  axios.get(`settings/remove-extra-trunks`).then(() => {
    toast({
      message: "Устаревшие каналы успешно удалены",
      type: "is-success",
    });
  }).catch(error => {
    toast({
      message: error.response.data.detail,
      type: "is-danger",
    });
  })
}
</script>

<template>
  <header>
    <div>Пользователь: {{ user }} / Роль: {{ role }}</div>
    <nav style="display: flex; justify-content: space-between">
      <div class="mr-16">
        <div>
          <v-btn @click="selectProject()" color="blue" v-bind="props"
                 prepend-icon="mdi-server"
          >Каналы
          </v-btn>
        </div>
      </div>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn color="primary" v-bind="props">Настройки</v-btn>
        </template>
        <v-list>
          <v-list>
            <v-list-item @click="collectGoips()">
              <v-list-item-title>Собрать список шлюзов</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item @click="collectData()">
              <v-list-item-title>Запросить и сохранить все доступные каналы</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item @click="removeExtraTrunks()">
              <v-list-item-title>Удалить устаревшие каналы</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item @click="router.push({name: 'ListDirectories'})">
              <v-list-item-title>Справочники</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-if="['boss', 'accountant'].includes(role)">
            <v-list-item @click="router.push({name: 'Customers'})">
              <v-list-item-title>Клиенты (смс)</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-if="['accountant', 'boss'].includes(role)">
            <v-list-item @click="router.push({name: 'ActivateServiceCustomers'})">
              <v-list-item-title>Клиенты (активации)</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-if="['admin', 'boss'].includes(role)">
            <v-list-item @click="router.push({name: 'CreateUser'})">
              <v-list-item-title>Пользователи</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-if="['boss', 'accountant'].includes(role)">
            <v-list-item @click="router.push({name: 'ActivateServiceBilling'})">
              <v-list-item-title>Биллинг по сервисам активации</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list v-if="['boss'].includes(role)">
            <v-list-item @click="router.push({name: 'ListServicesInformation'})">
              <v-list-item-title>Служебная информация</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item @click="router.push({name: 'UploadExcelTrunks'})">
              <v-list-item-title>Загрузить информацию по транкам из excel</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list>
      </v-menu>
      <v-btn class="ml-2" color="blue" @click="logout()">Выйти</v-btn>
    </nav>
  </header>
</template>


<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #f2f2f2;
}

</style>